import { Heading, Stack, Text, VStack } from "@chakra-ui/react";

const Column = ({ title, description }) => {
  return (
    <VStack w="33%" p={8} textAlign="center">
      <Heading color="#DE732C" fontWeight="black">
        {title}
      </Heading>
      <Text>{description}</Text>
    </VStack>
  );
};

const ThreeColumn = () => {
  return (
    <Stack direction={{ base: "column", md: "row" }} p={16} py={36} spacing={8}>
      <Column
        title="INFLUENCERS"
        description="Celebrity endorsements, Paid Pump & Dump influencers, Alpha groups filled with betas.
Make a statement by defacing their pfp and letting their bot following that you no longer will accept their hype, and price action calls."
      />
      <Column
        title="ART REVEAL"
        description="NFT Token gated utility of a bullshit community filled with scammers, and empty promises.
Building up hype with a no art reveal launch so they can finish their shitty Fiverr artwork."
      />
      <Column
        title="DERIVATIVES"
        description="Copies of copies and from scammers and bots.
Stand against shitty derivatives by defacing them and creating your own!"
      />
    </Stack>
  );
};

export default ThreeColumn;
