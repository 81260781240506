import { Box } from "@chakra-ui/react";
import rugTrimImage from "../assets/raster/rug_trim.png";

const RugTrim = () => {
  return (
    <Box
      w="100%"
      bgImg={rugTrimImage}
      bgRepeat="repeat-x"
      h={48}
      bgSize="contain"
    />
  );
};

export default RugTrim;
