import { Heading, Image, Stack, VStack } from "@chakra-ui/react";
import y00tImage from "../assets/raster/y00t_6716.png";
const OccupyBanner = () => {
  return (
    <Stack
      direction={{ base: "column-reverse", md: "row" }}
      bg="#DE732C"
      w="100%"
      justify="center"
    >
      <VStack justify="end">
        <Image src={y00tImage} maxH={64} />
      </VStack>
      <VStack p={16} textAlign="center">
        <Heading fontFamily="chalkduster" fontSize={{ base: "4xl", md: "8xl" }}>
          occupy y00ts
        </Heading>
        <Heading fontWeight="black" fontSize={{ base: "4xl", md: "8xl" }}>
          TO BE ANNOUNCED
        </Heading>
      </VStack>
    </Stack>
  );
};

export default OccupyBanner;
