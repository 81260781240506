import { Box, Center, Heading, Image, Stack, VStack } from "@chakra-ui/react";
import Footer from "./Components/Footer";
import Fungivists from "./Components/Fungivists";
import Hero from "./Components/Hero";
import OccupyBanner from "./Components/OccupyBanner";
import RugTrim from "./Components/RugTrim";
import ThreeColumn from "./Components/ThreeColumn";
import mushroomLogo from "./assets/raster/favicon.png";

const App = () => {
  return (
    <VStack w="100%" spacing={0}>
      <Box w="100%">
        <Center bg="#DE732C" py={24}>
          <Stack
            color="black"
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "start" }}
            textAlign="center"
            spacing={4}
          >
            <Heading color="white" fontSize="8xl" fontWeight="black">
              JUST STOP
            </Heading>
            <Heading fontSize="8xl" fontWeight="black">
              RUGS
            </Heading>
            <Image src={mushroomLogo} />
          </Stack>
        </Center>
      </Box>
      <Hero />
      <RugTrim />
      <ThreeColumn />
      <OccupyBanner />
      <Fungivists />
      <Footer />
    </VStack>
  );
};

export default App;
