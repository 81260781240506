import { Box, Heading, Image, Stack, Text, VStack } from "@chakra-ui/react";

import greta from "../assets/raster/Greta-Toadstool.png";
import shiitake from "../assets/raster/Shiitake-Nakamoto.png";

const Fungivist = ({ imageSrc, name, description }) => {
  return (
    <VStack maxW={"32rem"} textAlign="center" align="center">
      <Image src={imageSrc} maxH={96} />
      <Heading color="#DE732C" fontWeight="black">
        {name.toUpperCase()}
      </Heading>
      <Text>{description}</Text>
    </VStack>
  );
};

const Fungivists = () => {
  return (
    <Box p={36}>
      <VStack>
        <Heading color="#DE732C" fontWeight="black" fontSize="4xl">
          FUNGIVISTS
        </Heading>
        <Stack direction={{ base: "column", md: "row" }}>
          <Fungivist
            imageSrc={greta}
            name="greta toadstool"
            description="Greta Toadstool is a anti-rug activist known for challenging community leaders to take responsibility for NFT rug mitigation. Toadstool’s activism began when she told her parents to DYOR and adopt lifestyle choices that reduced their chance of getting rugged."
          />
          <Fungivist
            imageSrc={shiitake}
            name="shiitake nakamoto"
            description="Shiitake Nakamoto is the pseudonymous fungus who rugged on his own private keys. Eager to repair the damage he’s done to society, and degens Shiitake became an anti-rug activist, throwing various side dishes, and demanding change."
          />
        </Stack>
      </VStack>
    </Box>
  );
};

export default Fungivists;
