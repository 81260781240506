import {
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import fungified from "../assets/raster/fungified.gif";
import controls from "../assets/raster/video_controls.png";
import bgImg from "../assets/raster/nft_background.png";
const tweetLink = new URL("intent/tweet", "https://twitter.com");
tweetLink.search = [
  "text",
  encodeURIComponent("Your PFPs are no match for @fungivist!"),
].join("=");

const Hero = () => {
  return (
    <Center w="100%" bgImg={bgImg} bgRepeat="no-repeat" bgSize="cover">
      <Stack
        direction={{ base: "column", md: "row" }}
        p={16}
        py={32}
        spacing={8}
        w="100%"
        maxW="86rem"
        justify="center"
        align="center"
      >
        <VStack
          color="#DE732C"
          textAlign={{ base: "center", md: "left" }}
          align={{ base: "center", md: "start" }}
          maxW="32rem"
        >
          <Heading fontSize="4xl">NON-FUNGIBILITY IS KILLING US</Heading>
          <Heading fontSize="2xl" color="white">
            FUNGIVISTS, UNITE AND DEMAND CHANGE.
          </Heading>
          <Heading fontSize="2xl">NO MORE EXIT LIQUIDITY, NO MORE RUGS</Heading>
          <Text color="white">
            Just Stop Rugs is a coalition of degens, and bag holders working
            together as fungivists. Actively raising awareness against the
            exploitation of degenerates everywhere.
          </Text>
          <HStack w="100%" justify="center">
            <Button
              bg="#DE732C"
              color="white"
              rounded="none"
              size="lg"
              as={Link}
              target="_blank"
              rel="noreferrer noopener"
              href={tweetLink.href}
            >
              Vandalize PFP
            </Button>
          </HStack>
        </VStack>
        <Center>
          <VStack>
            <VStack spacing={0}>
              <Image src={fungified} w="lg" __css={{ aspectRatio: "16/9" }} />
              <Image
                src={controls}
                w="lg"
                __css={{ aspectRatio: "1163/168" }}
              />
            </VStack>
            <Text color="#DE732C">
              <Text as="span" fontWeight="bold">
                Please note:
              </Text>
              The original PFP was behind the blockchain and remains unharmed
            </Text>
          </VStack>
        </Center>
      </Stack>
    </Center>
  );
};

export default Hero;
