import { HStack, Text } from "@chakra-ui/react";
import CC from "../assets/vector/cc";
import Zero from "../assets/vector/zero";
const Footer = () => {
  return (
    <HStack bg="black" py={4} justify="center" color="white" w="100%">
      <Text>JUST STOP RUGS</Text>
      <CC />
      <Zero />
    </HStack>
  );
};

export default Footer;
